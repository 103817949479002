import React from 'react'
import { graphql } from 'gatsby'
import LocalizedIndex from '../components/localizedIndex'
import { MainPageRawData } from '../types/types'
import { transformPageData } from '../state/helpers'

type IProps = {
  data: MainPageRawData
}

const IndexPage = ({ data }: IProps) => {
  return <LocalizedIndex locale="es" data={transformPageData(data)} />
}

export const pageQuery = graphql`
  query IndexQuerySpanish {
    ...PageFragment
  }
`

export default IndexPage
